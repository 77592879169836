import type { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  isLoading?: boolean;
  isError?: boolean;
};

const LoadedWrapper = ({
  isLoading = false,
  isError = false,
  children,
}: Props) => {
  if (isLoading || isError) {
    return null;
  }

  return children;
};

LoadedWrapper.displayName = 'LoadedWrapper';

export default LoadedWrapper;
