import { useTranslation } from 'next-i18next';

import type { ReactNode } from 'react';
import tw, { type TwStyle } from 'twin.macro';

import Icon from '@components/elements/Icon';
import { cssMerge } from '@utils/styleHelpers';

type Props = {
  children?: ReactNode;
  showLoadingText?: boolean;
  text?: string;
  styles?: {
    wrapper?: {
      css: TwStyle | TwStyle[] | null;
    };
    icon?: TwStyle;
  };
};

const Spinner = ({
  children,
  showLoadingText = true,
  text = '$*common.isDataLoading',
  styles = {},
}: Props) => {
  const { t } = useTranslation();

  const twWrapperStyle = cssMerge({
    defaultCss: tw`flex items-center justify-center`,
    ...(styles?.wrapper || {}),
  });

  const twIconStyle = cssMerge({
    defaultCss: tw`w-8 h-8 mr-3 text-primary animate-spin`,
    ...(styles?.icon || {}),
  });

  return (
    <div css={twWrapperStyle}>
      <Icon name="SpinnerThird" css={twIconStyle} />
      {children || (showLoadingText && t(text))}
    </div>
  );
};

Spinner.displayName = 'Spinner';

export default Spinner;
