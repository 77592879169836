import { useTranslation } from 'next-i18next';

import { useEffect, useState } from 'react';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import tw, { type TwStyle } from 'twin.macro';

import { cssMerge } from '@utils/styleHelpers';

type Props = {
  styles?: {
    css: TwStyle | TwStyle[] | null;
  };
};

const LoadingTimeout = ({ styles }: Props) => {
  const { t } = useTranslation();

  const [clockIsVisible, setClockIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setClockIsVisible(true);
    }, 2000);

    return () => {
      setClockIsVisible(false);
      clearTimeout(timer);
    };
  }, []);

  if (!clockIsVisible) {
    return null;
  }

  const twStyle = cssMerge({
    defaultCss: tw`mb-8 text-center`,
    ...styles,
  });

  return (
    <div css={twStyle}>
      <FontAwesomeIcon icon={faClock} tw="text-6xl mb-4" />
      <div className="h2" tw="mb-0">
        {t('$*components.loadingTimeout.title')}
      </div>
      <p>{t('$*components.loadingTimeout.paragraph')}</p>
    </div>
  );
};

LoadingTimeout.displayName = 'LoadingTimeout';

export default LoadingTimeout;
