import getServerSideTranslations from '@utils/getServerSideTranslations';
import CustomPage from '@views/CustomPage/CustomPage.container';

export const getServerSideProps = async ({ locale }) => {
  return {
    props: {
      ...(await getServerSideTranslations(locale)),
      bodyClassName: 'page--custom',
      locale,
    },
  };
};

export default CustomPage;
