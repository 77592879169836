import { type ReactNode } from 'react';
import isEmpty from 'lodash/isEmpty';

import Box from '@components/elements/Box';
import Seo from '@components/modules/Seo/Seo';
import SeoCms from '@components/modules/Seo/SeoCms';
import { getChildByDisplayName } from '@utils/helpers';

type Props = {
  children: ReactNode;
  pageName: string;
  title?: string;
  customSeoSettingsKey?: string;
};

const PageLayout = ({
  children,
  pageName,
  title,
  customSeoSettingsKey,
}: Props) => {
  const ContentChildren = getChildByDisplayName(children, 'Content');
  const ContainerContentChildren = getChildByDisplayName(
    children,
    'ContainerContent'
  );

  return (
    <>
      {customSeoSettingsKey ? (
        <SeoCms
          pageName={pageName}
          customSeoSettingsKey={customSeoSettingsKey}
        />
      ) : (
        <Seo pageName={pageName} />
      )}
      {title && <h1 tw="mb-8 text-center">{title}</h1>}
      <div className="container">
        {!isEmpty(ContentChildren) && <Box>{ContentChildren}</Box>}
        {ContainerContentChildren}
      </div>
    </>
  );
};

type ContentProps = { children: ReactNode };

const Content = ({ children }: ContentProps) => {
  return children;
};
const ContainerContent = ({ children }: ContentProps) => {
  return children;
};

Content.displayName = 'Content';
ContainerContent.displayName = 'ContainerContent';

PageLayout.displayName = 'PageLayout';
PageLayout.Content = Content;
PageLayout.ContainerContent = ContainerContent;

export default PageLayout;
