import { useTranslation } from 'next-i18next';

import tw, { type TwStyle } from 'twin.macro';

import Button from '@components/elements/Button';
import ROUTE_URLS from '@constants/routeUrls';
import { cssMerge } from '@utils/styleHelpers';

type Props = {
  isError?: boolean;
  justify?: 'left' | 'center' | 'right';
  styles?: TwStyle;
};

const ErrorLoading = ({
  isError = false,
  justify = 'center',
  styles = {},
}: Props) => {
  const { t } = useTranslation();

  if (!isError) {
    return null;
  }

  const justifyStyle = {
    left: { wrapper: {}, btnWrapper: {} },
    center: { wrapper: tw`text-center`, btnWrapper: tw`mx-auto` },
    right: { wrapper: tw`text-right`, btnWrapper: tw`ml-auto` },
  }[justify];

  const twStyle = cssMerge({
    defaultCss: justifyStyle.wrapper,
    ...(typeof styles?.wrapper === 'object' && 'wrapper' in styles
      ? styles.wrapper
      : {}),
  });

  return (
    <div css={twStyle}>
      <div className="h2" tw="mb-0">
        {t('$*components.errorLoading.title')}
      </div>
      <p>{t('$*components.errorLoading.paragraph')}</p>
      <div css={[tw`table mt-4`, justifyStyle.btnWrapper]}>
        <Button
          onClick={() => {
            window.location.href = ROUTE_URLS.HOME;
          }}
        >
          {t('$*errorPage.backToHomePage')}
        </Button>
      </div>
    </div>
  );
};

ErrorLoading.displayName = 'ErrorLoading';

export default ErrorLoading;
