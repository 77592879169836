import { useQuery } from '@tanstack/react-query';

import { ENDPOINT } from '@constants/endpoint';
import axios from '@utils/axiosInstance';

export const getCustomPage = async pageId => {
  const response = await axios.get(`${ENDPOINT.PAGES}/${pageId}`);

  return response?.data ?? {};
};

const useCustomPage = pageId => {
  const query = useQuery({
    queryKey: ['customPage', pageId],
    queryFn: () => getCustomPage(pageId),
  });

  return query;
};

export default useCustomPage;
