import { useRouter } from 'next/router';

import ApiLoadingBoundary from '@components/elements/Loading/ApiLoadingBoundary';
import PageLayout from '@components/layouts/PageLayout';
import {
  selectPages,
  useAppConfigSelector,
} from '@hooks/useAppConfigSelectors';
import useCustomPage from '@hooks/useCustomPage';

const CustomPage = () => {
  const router = useRouter();
  const pages = useAppConfigSelector(selectPages);

  const { pageName } = router.query;
  const { id = null } = pages?.find(({ slug }) => slug === pageName) ?? {};
  const { data, isLoading, isError } = useCustomPage(id);

  return (
    <PageLayout pageName={pageName} title={data?.title}>
      <PageLayout.Content>
        <ApiLoadingBoundary isError={isError} isLoading={isLoading}>
          <div dangerouslySetInnerHTML={{ __html: data?.content }} />
        </ApiLoadingBoundary>
      </PageLayout.Content>
    </PageLayout>
  );
};

export default CustomPage;
